// Dependencies
import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { useInView } from 'react-intersection-observer'
// Hooks & Helpers
import useRandomPosition from "../hooks/useRandomPosition"
import matchProductId from "../helpers/matchProductId"
// Wrappers
import Layout from "../wrappers/Layout"
// Components
import Image from "../components/Image"
import Note from "../components/Note"
import ProductThumbnail from "../components/ProductThumbnail"
import RichText from "../components/RichText"
import Video from "../components/Video"
import Rollovers, { RolloverPopup, useThumbnailRollovers } from "../components/Rollovers";


const Caption = ({ content }) => (
	<div className="mt p3">
		<RichText content={content} />
	</div>
)

const Product = ({ product, index }) => {

	const timer = useRef()
	const startDesktop = useRandomPosition(400, 100)
	const startMobile = useRandomPosition(50, 50)
	const [pos, setPos] = useState({x: startDesktop.x, y: startDesktop.y})
	const startTransform = `translate(${pos.x + 20}%, ${pos.y + 20}%)`
	const endTransform = `translate(${pos.x}%, ${pos.y}%)`

	const [show, set] = useState(false)

	useEffect(() => {
		// Yuck...
		if ((window.innerWidth >= 768) && (window.innerWidth > window.innerHeight)) {
			setPos(startDesktop)
		} else {
			setPos(startMobile)
		}

		timer.current = setTimeout(() => {
			set(true)
		}, index * 250)
		return () => clearTimeout(timer.current)
	}, [])

	const style = {
		transform: show ? endTransform : startTransform,
		opacity: show ? 1 : 0,
		transition: `opacity 0.75s, transform 0.75s`
	}

	return (
		<div className="story__product" style={style}>
			<ProductThumbnail product={product} />
		</div>
		
	)
}

const StoryProducts = ({ products }) => {

	return (
		<div className="story__products">
			{products.map((entry, index) => (
				<Product product={entry} index={index} key={entry.shopifyId} />
			))}
		</div>
	)
}

const ProductLink = ({ onClick, inView }) => {

	return (
		<button className={`story__products__toggle p3 ${inView ? 'visible' : ''}`} onClick={onClick}>View product(s)</button>
	)
}

export const Story = ({ 
  contentful_id, 
  image, 
  note, 
  video, 
  caption, 
  productLinks,
  imagePortrait,
  linkLabel,
  internalLink,
  textColour = 'black',
}) => {

  // Content
  const portraitImage = imagePortrait || image
  const link = productLinks?.length === 1 ? `/product/${productLinks[0].handle}` : internalLink

	// UX
	const [showProducts, setShowProducts] = useState(false)

	const { ref, inView } = useInView({
    threshold: 0.33
  });

	// UI
	const toggle = () => {
		setShowProducts(o => !o)
	}

	// Helpers
	const anchor = `story${contentful_id}`

  // console.log(caption)

	return (
		image ? (
			<div id={anchor} className="gallery__story has-thumbnails" ref={ref}>
				{productLinks?.length ? <ProductLink onClick={toggle} inView={inView} /> : null}
				<Image className="show-desktop" image={image.gatsbyImageData} />
        <Image className="show-mobile" image={portraitImage.gatsbyImageData} />
				{caption && <Caption content={caption} />}
				{showProducts && productLinks && (
					// <StoryProducts products={productLinks} showProducts={showProducts} />
					<Rollovers>
						{productLinks.map((product, index) => (
							<RolloverPopup index={index-1} key={`rollover-${product.shopifyId}`}>
								<ProductThumbnail product={product} />
							</RolloverPopup>
						))}
					</Rollovers>
				)}
        {linkLabel && (
          <h3 className={`pabs t0 l0 innerx2 guttersx2 text-${textColour}`}>{linkLabel}</h3>
        )}
			</div>
		) : note ? (
			<div id={anchor} className="gallery__story">
				<Note content={note} />
			</div>
		) : video ? (
			<div id={anchor} className="gallery__story">
				<Video 
          className="vimeo-player story-video" 
          src={video.mp4?.file?.url} 
          vimeoUrl={video.vimeoUrl} 
          disableFullscreen={link ? true : false} 
        />
				{caption && <Caption content={caption} />}
        {linkLabel && (
          <h3 className={`pabs t0 l0 innerx2 guttersx2 text-${textColour}`}>{linkLabel}</h3>
        )}
			</div>
		) : null
	)
}

const ChapterGallery = ({ data, pageContext }) => {

	const allShopifyProduct = data.allShopifyProduct.nodes
	const stories = data.contentfulChapterGallery?.stories?.map((story) => {
		const productLinks = story.productLinks?.map((product) => {
			const shopifyProduct = allShopifyProduct?.find((item) => {
				// return item.variants[0].storefrontId === product.shopifyId
				return item.variants.find(variant => matchProductId(variant.storefrontId, product?.shopifyId))
			})
			return {
				...product,
				...shopifyProduct
			}
		})
		return {
			...story,
			productLinks: productLinks
		}
	})

  return (
    <Layout>
			<section className="grid padx4">
				<div className="span-6 start-7">
					{stories?.map((entry, index) => (
						<Story {...entry} index={index} key={entry.id} />
					))}
				</div>
			</section>
    </Layout>
  )
}

ChapterGallery.propTypes = {
  data: PropTypes.object.isRequired
}

export default ChapterGallery

export const ChapterGalleryPageQuery = graphql`
  query ChapterGalleryQuery($chapter: String!, $gallery: String!) {
    contentfulChapterGallery(chapter: {slug: {eq: $chapter}}, slug: {eq: $gallery}) {
      stories {
				contentful_id
				id
				image {
					gatsbyImageData(
						layout: FULL_WIDTH
						quality: 90
						formats: [JPG]
					)
				}
				note {
					raw
				}
				caption {
					raw
				}
				video {
					mp4 {
						file {
							url
						}
					}
          vimeoUrl
          caption {
            raw
          }
				}
				productLinks {
					shopifyId
					title
					colourMaterial
				}
			}
    }
		allShopifyProduct {
			nodes {
				handle
				featuredImage {
					gatsbyImageData(layout: FULL_WIDTH)
				}
				images {
					gatsbyImageData(layout: FULL_WIDTH)
					medium: gatsbyImageData(layout: CONSTRAINED, width: 640)
        	large: gatsbyImageData(layout: FULL_WIDTH)
				}
				options {
					name
					values
				}
				variants {
					availableForSale
					storefrontId
					price
					compareAtPrice
					selectedOptions {
						value
					}
				}
			}
		}
  }
`
//($gallery: String!, $chapter: String!)
// contentfulChapterGallery(chapter: {elemMatch: {slug: {eq: $chapter}}}, slug: {eq: $gallery})
